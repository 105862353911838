@import "../common/defaults.scss";

.scope-breadcrumb {
    letter-spacing: 0.3px;

    .mobile-only-breadcrumb-container {
        height: 100%;
        margin-top: 1px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .b_c_items {
            display: flex;
            width: calc(100% + 1px);
            font-size: 14px;
            font-weight: unset;
            margin: 0;

            .first-item {
                font-weight: bold;
                color: $bc-black;
                position: relative;
                display: inline-block;
                height: 100%;
                text-transform: uppercase;
                white-space: nowrap;
                font-size: 14px;
                line-height: 42px;
                padding-left: 10px;
                margin-left: initial;

                &:not(.only)::after {
                    display: inline-block;
                    content: '-';
                    padding-left: 10px;
                    padding-right: 5px;
                    color: $bc-text-grey;
                }

                &.only {
                    padding-right: 10px;
                    background: white;

                    &::after {
                        overflow-y: visible;
                        content: '';
                        display: block;
                        height: 5px;
                        background: $bc-red;
                        margin-top: -5px;
                    }
                }

                .gradient {
                    position: absolute;
                    width: 25px;
                    height: calc(100% - 5px);
                    top: 3px;
                    right: -25px;
                    background: linear-gradient(90deg,#fff 0%,rgba(255,255,255,0) 100%);
                    z-index: 2;
                }
            }

            .mobile-only-breadcrumb-wrapper {
                flex-shrink: 2;
                overflow-x: hidden;
                white-space: nowrap;
                -ms-overflow-style: none;
                scrollbar-width: none;
                direction: rtl;
                position: relative;
                max-width: 580px;

                .breadcrumb-category {
                    display: inline-block;
                    height: 100%;
                    text-transform: uppercase;
                    white-space: nowrap;
                    text-decoration: none;
                    direction: ltr;
                    padding-left: 5px;
                    font-size: 14px;
                    line-height: 42px;
                    color: $bc-text-grey;

                    &:not(:first-of-type)::after {
                        display: inline-block;
                        content: '-';
                        padding-right: 5px;
                        padding-left: 10px;
                        height: 100%;
                        color: $bc-text-grey;
                    }

                    &:first-of-type {
                        background-color: white;
                        padding: 0 5px 0 5px;
                        padding-right: 5px;

                        &::after {
                            overflow-y: visible;
                            content: '';
                            display: block;
                            height: 5px;
                            margin-top: -7px;
                            background: $bc-red;
                        }
                    }
                }

                .breadcrumb-back-button-mobile {
                    text-transform: uppercase;
                    font-weight: bold;
                    color: $bc-black;
                    cursor: pointer;
                    white-space: pre;
                    margin-bottom: -28px;
                    font-size: 14px;
                    padding-right: 16px;
                    padding-left: 7px;
                    padding-top: 10px;
                    display: inline-block;
                    height: 31px;
                    background: white;
                }
            }
        }

        .mobile_border {
            margin-right: 5px;
            display: block;
            border-bottom: 1px solid $bc-black;
            margin-top: -2px;
            margin-left: 10px;
            width: 100%;
        }
    }

    &.detail {
        &.detailspage {
            .fix .breadcrumb-wrapper {
                height: 60px;
            }

            .mobile-only-breadcrumb-container {
                margin-top: 0;
                background-color: white;
                width: 100%;
                padding-top: 8px;

                .b_c_items {
                    .first-item {
                        color: $bc-black;

                        .gradient {
                            background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
                        }
                    }

                    .breadcrumb-category {
                        color: $bc-text-grey;
                        font-size: 12px;
                    }

                    .breadcrumb-category:first-of-type {
                        background-color: white;
                        color: $bc-text-grey;
                    }

                    .breadcrumb-back-button-mobile {
                        background: inherit;
                        color: $bc-black;
                    }
                }
            }
        }
    }

    &.enableRedesignCategoryPageAndSearchPage {
        .mobile-only-breadcrumb-container {
            width: 100%;
        }
    }
}
