@import "../common/defaults.scss";

.scope-breadcrumb {
    position: sticky;
    z-index: 1040;
    margin-top: -60px;
    margin-left: -20px;
    margin-right: -20px;
    top: 48px;
    letter-spacing: 0.3px;

    &.isGlobal {
        top: 84px;
    }

    &.alternative {
        margin-top: 0;
    }

    .border {
        border-top: none;
        border-bottom: 1px solid $bc-black;
        margin-top: -1px;
        margin-bottom: 0px;
    }

    .breadcrumb-wrapper {
        width: 100%;
        background-color: white;
        padding-top: 45px;
        height: 70px;
        display: flex;

        .breadcrumb-container {
            height: 100%;
            margin-top: 1px;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .b_c_items {
                display: flex;
                width: calc(100% + 1px);
                letter-spacing: 0.3px;
                line-height: 20px;

                // Needed for https://jira.ztna.cid.com/browse/ESPP-10439
                .additional_padding {
                    padding: 1px 1px 0 1px;
                }

                .remove_outline {
                    outline: none;
                }

                .first-item {
                    margin-left: 1px;
                    padding-left: 0;
                    font-weight: bold;
                    color: $bc-black;
                    position: relative;
                    display: inline-block;
                    height: 100%;
                    font-size: $bc-font-size;
                    text-transform: uppercase;
                    white-space: nowrap;

                    &:not(.only)::after {
                        display: inline-block;
                        content: '-';
                        padding-left: 10px;
                        padding-right: 5px;
                        color: $bc-text-grey;
                    }

                    &.only {
                        padding-right: 10px;
                        background: white;

                        &::after {
                            overflow-y: visible;
                            content: '';
                            display: block;
                            height: 5px;
                            margin-top: 1px;
                            background: $bc-red;
                        }
                    }

                    .gradient {
                        position: absolute;
                        width: 25px;
                        height: calc(100% - 5px);
                        top: 3px;
                        right: -25px;
                        background: linear-gradient(90deg,#fff 0%,rgba(255,255,255,0) 100%);
                        z-index: 2;

                        &.hidden {
                            display: none;
                        }
                    }
                }

                .has-focus-keyboard {
                    outline: none;

                    .first-item {
                        height: 17px;
                        line-height: 17px;

                        span {
                            outline: 1px solid $bc-black;    
                        }
                    }

                    .only {
                        line-height: 20px;
                        height: 26px;
                    }
                }

                .breadcrumb-category-wrapper {
                    flex-shrink: 2;
                    overflow-x: scroll;
                    white-space: nowrap;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    direction: rtl;
                    position: relative;
                    max-width: 580px;

                    .breadcrumb-category {
                        display: inline-block;
                        height: 25px;
                        font-size: $bc-font-size;
                        text-transform: uppercase;
                        white-space: nowrap;
                        text-decoration: none;
                        direction: ltr;
                        padding-left: 5px;
                        color: $bc-text-grey;

                        &:not(:first-of-type)::after {
                            display: inline-block;
                            content: '-';
                            padding-right: 5px;
                            padding-left: 10px;
                            height: 100%;
                            color: $bc-text-grey;
                        }

                        &:first-of-type {
                            background-color: white;
                            padding: 0 10px 0 5px;

                            &::after {
                                overflow-y: visible;
                                content: '';
                                display: block;
                                height: 5px;
                                background: $bc-red;
                            }
                        }

                        &.has-focus-mouse {
                            outline: none;
                            text-decoration: none;

                            a {
                                color: $bc-black;
                            }
                        }

                        .cat_link {
                            color: $bc-black;

                            &:hover {
                                color: $bc-text-grey;
                            }
                        }

                        &.has-focus-keyboard {
                            outline: none;
    
                            .cat_link {
                                outline: 1px solid $bc-black;
                                outline-offset: 1px;
                            }
                        }
                    }
                }

                .breadcrumb-category-wrapper::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        .search-result {
            height: 100%;
            flex: 1;
            color: $bc-text-grey;

            big {
                color: $bc-red;
                font-size: $bc-font-size;
            }
        }

        .alternative-search-result {
            display: flex;
            align-items: flex-end;
            padding-bottom: 5px;
            padding-left: 20px;
            line-height: 20px;

            .search-query {
                font-weight: bold;
                font-size: 17px;
                color: $bc-black;
                text-transform: uppercase;
            }

            .result-count {
                font-size: 14px;
                padding-left: 13px;
                color: $bc-text-grey;
            }
        }

        .button-container {
            display: flex;
            align-items: center;
            margin-top: -10px;
            margin-bottom: 28px;
            margin-left: auto;
            font-size: 14px;

            &.remove_outline {
                outline: none;
            }

            .product-finder-button {
                height: 48px;
                background-color: $bc-light-grey-2021;
                border-radius: 24px;
                padding-right: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                color: $bc-black;
                transition: background-color 0.3s;

                &:hover {
                    background-color: $bc-mid-grey;
                }

                .product-finder-logo-title {
                    height: 40px;
                    padding-left: 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &.has-focus-keyboard {
                        outline: none;
                        text-decoration: none;
                        filter: invert(30%);
                    }
                    
                    .logo {
                        height: 22px;
                        width: 22px;
                        margin-right: 5px;
                        background-image: url('../../Assets/img/productfinder_2021.svg');
                        background-size: 22px 22px;
                    }

                    .button-title {
                        color: $bc-black;
                        display: inline-block;
                        height: 100%;
                        line-height: 24px;
                        white-space: nowrap;
                        font-size: 14px;
                        padding: 8px 0;
                        font-weight: 500;
                    }
                }

                .close-icon-area-containter {
                    &.has-focus-keyboard {
                        outline: none;
                        text-decoration: none;
                        filter: invert(30%);
                    }

                    .close-icon-area {
                        padding: 7px;
                        margin-right: -10px;
                        cursor: pointer;
                        float: right;

                        .close-icon {
                            background-image: url("../../Assets/img/close_icon.svg");
                            height: 22px;
                            width: 22px;
                        }
                    }
                }
            }

            .breadcrumb-back-button {
                text-transform: uppercase;
                font-size: $bc-font-size;
                font-weight: bold;
                color: $bc-black;
                cursor: pointer;
                white-space: pre;
                margin-bottom: -32px;
            }

            &.has-focus-keyboard {
                outline: none;

                .breadcrumb-back-button {
                    span {
                        outline: 1px solid $bc-black;
                    }
                }
            }
        }
    }

    &.isMobile {
        position: inherit;
        margin: 0;
        width: 100%;

        .border {
            display: none;
        }

        hr {
            border-top: none;
        }

        .breadcrumb-wrapper {
            height: 42px;
            margin-right: 5px;
            padding-top: 0px;

            .search-result {
                height: auto;
                margin: auto 12px;

                big {
                    color: $bc-black;
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .alternative-search-result {
                padding: 0 30px 12px;
            }
        }
    }

    .sticky-gradient {
        height: 10px;
        background-image: linear-gradient(#ffffff, rgba(255, 255, 255, 0.0));
    }

    &.detail {
        margin-top: -75px;

        .sticky-gradient {
            margin-bottom: 10px;
        }

        &.detailspage {
            margin: 0;
            top: 0;

            .fix {
                .breadcrumb-wrapper {
                    padding-top: 0;
                }

                .border {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        &.detail.detailspage .fix .breadcrumb-wrapper {
            height: initial;
        }
    }

    @media screen and (max-width: 767px) {
        &.detail {
            margin-top: unset;
            margin-bottom: 12px;
        }
    }

    html.isFirefox &.breadcrumb-sticky {
        width: 1000px;
        position: fixed;
        margin-top: 0;

        &.enableRedesignCategoryPageAndSearchPage {
            width: initial;
        }
    }


}

.fas_productfinderresultpage .scope-breadcrumb {
    .breadcrumb-wrapper {
        .breadcrumb-container {
            flex: 1;
        }

        .button-container {
            margin: 0;
            align-items: flex-start;
            padding-left: 20px;
            background-color: white;

            .product-finder-button {
                background-color: $bc-black;

                &:hover {
                    background-color: $bc-mid-grey;
                }

                .product-finder-logo-title {
                    .logo {
                        background-image: url('../../Assets/img/productfinder_2021_light.svg');
                    }

                    .button-title {
                        color: white;
                    }
                }

                .close-icon-area-containter {
                    .close-icon-area {
                        .close-icon {
                            background-image: url("../../Assets/img/close_icon_light.svg");
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .scope-breadcrumb {
        &.detail {
            &.detailspage {
                padding: 15px 15px 5px 15px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .scope-breadcrumb {
        &.enableRedesignCategoryPageAndSearchPage {
            &#ats_pfr_mobile_breadCrumb {
                .breadcrumb-wrapper {
                    &.hide-wrapper {
                        display: none;
                    }
                }
            }
        }
    }
}
