@import "../common/defaults.scss";

.scope-breadcrumb {
    letter-spacing: 0.3px;

    .product-finder-button-container {
        background-color: white;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding: 10px 0;

        .product-finder-button {
            height: 48px;
            background-color: $bc-light-grey-2021;
            border-radius: 24px;
            cursor: pointer;
            margin-right: 5px;
            align-items: center;
            padding: 0 20px;
            display: flex;
            color: $bc-black;
            transition: background-color 0.3s;

            &:hover {
                background-color: $bc-mid-grey;
            }

            .logo {
                height: 22px;
                width: 22px;
                margin-right: 5px;
                background-image: url('../../Assets/img/productfinder_2021.svg');
                background-size: 22px 22px;
            }

            .button-title {
                color: $bc-black;
                display: inline-block;
                height: 100%;
                line-height: 48px;
                white-space: nowrap;
                font-size: 14px;
                padding: 8px 0;
            }
        }

        .close-icon-area {
            margin-right: 10px;
            cursor: pointer;
            float: right;

            .close-icon {
                background-image: url('../../Assets/img/close_icon.svg');
                height: 48px;
                width: 48px;
                background-size: cover;
            }
        }
    }
}
